body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #333;
  color: #fff;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  width: 100%;
  margin-bottom: 10px;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #fff;
}

h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #fcd0d0;
}

h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #fcd0d0;
}

input[type="number"] {
  padding: 10px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1.2rem;
  width: auto;
  background-color: #444;
  color: #fcd0d0;
}

input[type="number"]:focus {
  outline: none;
  border-color: #007bff;
}

label {
  margin-right: 10px;
  font-size: 1.2rem;
  color: #fcd0d0;
  width: 100px;
  text-align: right;
  display: inline-block;
}

.total {
  font-size: 2rem;
  margin-top: 30px;
  color: #007bff;
}
